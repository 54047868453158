"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const chart_js_1 = require("chart.js");
const i18n_1 = require("@/client/directives/i18n");
chart_js_1.Chart.register(...chart_js_1.registerables);
chart_js_1.Chart.defaults.font.size = 20;
chart_js_1.Chart.defaults.font.family = 'Ubuntu, Sans';
chart_js_1.Chart.defaults.color = 'rgb(240, 240, 240)';
const COLOR_CODES = {
    ['red']: 'rgb(153, 17, 0)',
    ['yellow']: 'rgb(170, 170, 0)',
    ['green']: 'rgb(0, 153, 0)',
    ['black']: 'rgb(170, 170, 170)',
    ['blue']: 'rgb(0, 102, 255)',
    ['purple']: 'rgb(140, 0, 255)',
    ['orange']: 'rgb(236, 113, 12)',
    ['pink']: 'rgb(245, 116, 187)',
    ['neutral']: '',
    ['bronze']: '',
};
exports.default = vue_1.default.extend({
    name: 'VictoryPointChart',
    data: function () {
        return {};
    },
    props: {
        datasets: {
            type: Array,
        },
        generation: {
            type: Number,
        },
        animation: {
            type: Boolean,
        },
        id: {
            type: String,
        },
        yAxisLabel: {
            type: String,
            required: false,
            default: 'Victory Points',
        },
    },
    methods: {
        getLabels: function () {
            return Array.from({ length: this.generation }, (_, index) => index + 1);
        },
        getAllPlayerDataSet: function () {
            return this.datasets.map((dataset) => {
                return {
                    label: dataset.label,
                    data: dataset.data,
                    fill: false,
                    backgroundColor: COLOR_CODES[dataset.color],
                    borderColor: COLOR_CODES[dataset.color],
                    tension: 0.1,
                    pointRadius: 6,
                };
            });
        },
        renderChart: function () {
            const ctx = document.getElementById(this.id);
            if (ctx !== null) {
                new chart_js_1.Chart(ctx, {
                    type: 'line',
                    data: {
                        labels: this.getLabels(),
                        datasets: this.getAllPlayerDataSet(),
                    },
                    options: {
                        animation: {
                            duration: this.animation ? 1000 : 0,
                            easing: 'linear',
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            y: {
                                title: { text: (0, i18n_1.translateText)(this.yAxisLabel), display: true },
                                grid: {
                                    color: (ctx) => {
                                        return ctx.tick.value % 10 === 0 ? 'lightgray' : 'rgb(90, 90, 90)';
                                    },
                                },
                                beginAtZero: true,
                                ticks: {
                                    autoSkip: false,
                                    stepSize: 5,
                                    callback: (value) => {
                                        if (typeof (value) === 'string')
                                            return value;
                                        return value % 10 === 0 ? value : '';
                                    },
                                },
                            },
                            x: {
                                title: { text: (0, i18n_1.translateText)('Generation'), display: true },
                                offset: true,
                            },
                        },
                    },
                });
            }
        },
    },
    mounted() {
        this.renderChart();
    },
});

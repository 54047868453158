"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = vue_1.default.extend({
    name: 'CardParty',
    props: {
        party: {
            required: true,
            type: String,
        },
        size: {
            required: false,
            default: 'card',
            type: String,
        },
    },
    computed: {
        classes() {
            const p = this.party.toLowerCase().replace(' ', '-');
            const suffix = this.size === 'req' ? '-req' : '';
            return `card-party--${p}${suffix}`;
        },
    },
});

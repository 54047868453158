"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const Tag_vue_1 = require("@/client/components/Tag.vue");
exports.default = vue_1.default.extend({
    name: 'tag-count',
    props: {
        tag: {
            type: String,
        },
        count: {
            type: String,
        },
        size: {
            type: String,
        },
        type: {
            type: String,
        },
        showWhenZero: {
            required: false,
            default: false,
        },
    },
    components: {
        Tag: Tag_vue_1.default,
    },
    computed: {
        outerClass() {
            const classes = ['tag-display'];
            if (this.count === 0 && this.showWhenZero === false) {
                classes.push('tag-no-show');
            }
            return classes.join(' ');
        },
        innerClass() {
            const classes = ['tag-count-display'];
            if (this.count === 0 && this.showWhenZero === false) {
                classes.push('tag-count-no-show');
            }
            return classes.join(' ');
        },
    },
});
